.trainer_coverPic {
    height: 90vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.trainer_coverPic>h1 {
    font-size: 60px;
    color: #047E7F;
    margin: 20px 0;
    font-style: italic;
}

.qr {
    width: 15vw;
    border-radius: 20px;
    border: inset;
    box-shadow: 3px 3px;
}

.qrContainer {
    text-align: center;
}

.qrContainer p {
    font-weight: bold;
    margin-top: 5px;
}

/* ============= RESPONSIVE ============= */

/* Extra small devices (phones, 500px and down) */
@media only screen and (max-width: 500px) {
    .trainer_coverPic {
        height: 100vh;
    }

    .trainer_coverPic h1 {
        font-size: 42px;
    }
}


/* Small devices (portrait tablets and large phones, 500px and up) */
@media only screen and (min-width: 500px) {
    .trainer_coverPic {
        height: 100vh;
    }

    .trainer_coverPic h1 {
        font-size: 46px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .trainer_coverPic h1 {
        font-size: 50px;
    }
}


/* Large devices (laptops/desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .trainer_coverPic {
        height: 92vh;
    }

    .trainer_coverPic h1 {
        font-size: 60px;
    }
}