.mainContainer {
    background-color: #636161;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5rem;
    height: 12vh;
    font-weight: 500;
    /* border-bottom: 1px solid; */
 
}

.logo {
    height: 10vh;
    cursor: pointer;
    border-radius: 1em;
    margin-right: 0.5em;
}

.right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.joinNowNavbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    background-color: #ec1313;
    height: 12vh;
    width: 14vw;
    color: #F7FDFD;
    padding: 0 25px;
}

.hvr:hover {
    cursor: pointer;
    color: #3be1e4;
}

.hvrj:hover {
    cursor: pointer;
    color: #3be1e4;
}

.link {
    text-decoration: none;
    color: white;
}

.left {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.title {
    color: #3be1e4;
}

.coursesLink {
    position: relative;
}

.coursesDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    width: 20vw;
    padding: 10px;
    z-index: 100;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

}

.coursesDropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.coursesDropdown ul a {
    color: #007bff;
    text-decoration: none;
}

.coursesDropdown li {
    padding: 5px 10px;
    cursor: pointer;
}

.coursesDropdown li:hover {
    background-color: #696666;
    border-radius: 5px;
}

.active {
    position: relative;
    font-weight: bold;
}

.active::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -4px;
    transform: translateX(-50%);
    width: 110%;
    height: 3px;
    background-color: #3be1e4;
    border-radius: 3px;
}

/* Hamburger Menu */
.hamburger {
    display: none;
    cursor: pointer;
}

.showMenu {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

@media (max-width: 768px) {
    .right {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .showMenu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 12vh;
        right: 0;
        background-color: #636161;
        width: 100%;
        padding: 1em;
        z-index: 200;
    }

    .joinNowNavbar {
        width: 100%;
    }

    .mainContainer {
        padding: 0 2rem;
    }

    .coursesDropdown {
        width: 30vw;
        /* Increase the dropdown width for smaller screens */
        padding: 5px;
        font-size: 0.9rem;
        /* Slightly smaller font for mobile screens */
    }

    .coursesDropdown li {
        padding: 8px 5px;
        /* Adjust padding for smaller screens */
    }

    .coursesDropdown ul a {
        font-size: 0.9rem;
        /* Smaller font size for mobile view */
    }
}

/* Hamburger Menu */
.hamburger {
    display: none;
    cursor: pointer;
}

/* Updated the showMenu class */
.showMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 12vh;
    /* Just below the navbar */
    right: 2rem;
    /* A little spacing from the right edge */
    background-color: #636161;
    width: 200px;
    /* Set a specific width for the menu */
    padding: 1em;
    border-radius: 10px;
    /* Optional: to make the menu look smoother */
    z-index: 200;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
}

.joinNowNavbar {
    width: 100%;
    /* Full width of the menu container */
}

@media (max-width: 768px) {
    .right {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .showMenu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 12vh;
        right: 1rem;
        /* Space from the right edge */
        width: 200px;
        /* Set specific width for the mobile menu */
        background-color: #636161;
        padding: 1em;
        border-radius: 10px;
        /* Rounded corners for a smoother look */
    }
}