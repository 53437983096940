.paymentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to right, #f9f9f9, #e9ecef);
    padding: 20px;
    background-image: url('https://res.cloudinary.com/dkasinto0/image/upload/v1726806341/logo-Ve-Minds_bfc8ut.jpg');
    background-size: cover;
    background-attachment: "fixed";
    background-repeat: "no-repeat";
    background-position: 'top';

}

.paymentForm {
    background: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.paymentForm h2 {
    margin-bottom: 20px;
    color: #333;
}

.formGroup {
    margin-bottom: 20px;
    text-align: left;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.formGroup input {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.formGroup input:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.errorInput {
    border-color: red;
}

.errorText {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.termsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.termsContainer label {
    margin-left: 8px;
    color: #555;
}

.termsContainer a {
    color: #007bff;
    text-decoration: none;
}

.termsContainer a:hover {
    text-decoration: underline;
}

.payButton {
    background-color: #28a745;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%;
    font-size: 16px;
}

.payButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.payButton:not(:disabled):hover {
    background-color: #218838;
    transform: scale(1.05);
}

ul {
    text-align: justify;
}