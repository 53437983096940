.snakeBorder {
    position: relative;
    display: inline-block;
    padding: 5px;
    background-image: linear-gradient(to bottom, rgb(202, 193, 193), rgb(39, 27, 27));
    /* background: transparent; */
    padding: 1rem;
    text-align: center;
    height: 100%;
    border-radius: 20px;
    width: 40vw;
}

.top_startForFree {
    margin: 1em 0;
}

.bottom_startForFree {
    margin: 3em 0;

}

.buttonContainer_StartForFree {
    margin-top: 4rem
}

.snakeBorder::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
    box-sizing: border-box;
    border-image: linear-gradient(90deg, transparent, #33ff00, transparent) 1;
    border-radius: 8px;
    /* Optional: Add rounded corners */
    animation: snake 2s linear infinite;
}

@keyframes snake {
    0% {
        border-image-source: linear-gradient(90deg, transparent, #3be1e4, transparent, transparent);
    }

    25% {
        border-image-source: linear-gradient(180deg, transparent, #3be1e4, transparent, transparent);
    }

    50% {
        border-image-source: linear-gradient(270deg, transparent, #3be1e4, transparent, transparent);
    }

    75% {
        border-image-source: linear-gradient(360deg, transparent, #3be1e4, transparent, transparent);
    }

    100% {
        border-image-source: linear-gradient(450deg, transparent, #3be1e4, transparent, transparent);
    }
}

.main_courseSection {
    scroll-behavior: smooth;
}

.button_startForFree {
    background-image: radial-gradient(rgb(134, 49, 49), rgb(29, 209, 185));
}

.bg_image {
    /* background-image: url('https://images.unsplash.com/photo-1508615121316-fe792af62a63?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTh8fHxlbnwwfHx8fHw%3D');
    background-size: cover; */
    background-color: #d1d1c3;

}

.fullStack {
    display: flex;
    padding: 2rem;
    justify-content: space-around;
    background-image: url('https://images.unsplash.com/photo-1508615121316-fe792af62a63?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTh8fHxlbnwwfHx8fHw%3D');
    background-size: cover;
}

.font_color {
    color: #3BE1E4;
}

.caption {
    color: rgb(189, 182, 182);
    font-weight: bolder;
    font-size: 3.5em;
    margin-right: 0.5rem;
}

.text {
    color: rgb(189, 182, 182);
    font-size: 1em;
}

.title_mern {
    color: red;
}

.payAfterPlacement,
.access {
    font-size: 20px;
    font-weight: bolder;
    margin: 0 50px;
    padding: 10px;
    /* Add padding for better appearance */
    background-image: linear-gradient(to top right, #0c0c0c, rgb(255, 0, 0));
    /* Gradient background */
    -webkit-background-clip: text;
    /* Clip background to text */
    -webkit-text-fill-color: transparent;
    /* Make the text color transparent to show the gradient */
    display: inline-block;
    /* Ensures the background and border wrap tightly around the text */
    animation: blink 1s infinite;
    /* Blink effect */
    border: solid 0.3rem #3BE1E4;
    border-radius: 10px;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
        /* Fully visible */
    }

    50% {
        opacity: 0.5;
        /* Half transparent */
    }
}


.column_mern {
    /* border: solid 1px red; */
    padding: 20px;
    width: 35vw;
    background: transparent;
}

.topContainer {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    margin-right: 150px;
}

.columns_mern {
    display: flex;
    justify-content: space-around;
    padding: 0 2em;
}

.upArrow {
    width: 100px;
    font-weight: bolder;
}

.buttonAndArrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item_eligibility {
    display: flex;
    gap: 30px;
}

.column_mern {
    /* border: solid 1px #ddd; */
    padding: 20px;
    width: 40vw;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* Adds space between the items */
    align-items: flex-start;
    /* Align items to the start */
    background: transparent;
}

.item_eligibility {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.itemHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Space between the icon and the text */
}

.itemHeader span {
    font-size: 18px;
    font-weight: bold;
}

h6 {
    font-size: 16px;
    margin-left: 30px;
    /* Indent the description slightly for visual hierarchy */
    font-weight: normal;
    color: rgb(243, 239, 239);
    /* Optional, to soften the description text color */
}

/* Optional responsive adjustment */
@media (max-width: 768px) {
    .column_mern {
        width: 100%;
        padding: 10px;
    }
}

.details_fullStack {
    /* margin-top: 3rem; */
    padding: 4rem 0;
    background-image: radial-gradient(rgb(141, 132, 132), rgb(41, 40, 39));

}

.details_dataScience {
    /* margin-top: 3rem; */
    background-image: radial-gradient(rgb(173, 174, 177), rgb(50, 90, 105));
    padding: 4rem 0;
}

.details_dataAnalysis {
    /* margin-top: 3rem; */
    padding: 4rem 0;
    background-image: radial-gradient(rgb(181, 207, 34), rgb(50, 90, 105));
    font-weight: bolder;
}

.upskill {

    margin-bottom: 0px;
    font-weight: bolder;
    text-align: center;
}

.buttonAndArrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: none;
}

.technologyList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.technology {
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: calc(50% - 10px);
    /* Each item takes 50% width minus the gap */
}

/* Specific background colors for each technology */
.technology:nth-child(1) {
    background-color: #f0db4f;
    /* JavaScript - Yellow */
}

.technology:nth-child(2) {
    background-color: #61dbfb;
    /* React.js - Blue */
}

.technology:nth-child(3) {
    background-color: #68a063;
    /* Node.js - Green */
}

.technology:nth-child(4) {
    background-color: #4db33d;
    /* MongoDB - Green */
}

.technology:nth-child(5) {
    background-color: #000000;
    /* Express.js - Black */
    width: 100%;
    /* Center the last item by giving it full width */
    text-align: center;
}

/* Animation for the up arrow */
.upArrow {
    font-size: 50px;
    animation: moveUp 1.5s infinite ease-in-out, blink 1s infinite;
    color: #007bff;
    margin-top: 0;
    /* Negative margin to remove the space */
    margin-bottom: -20px;
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* Button styling */
button {
    margin-top: 0;
    /* Ensure no space between the arrow and button */
    padding: 12px 30px;
    font-size: 16px;
    font-weight: bold;
}


/* Responsive adjustments */
@media (max-width: 1200px) {
    .fullStack {
        flex-direction: column;
        padding: 1rem;
    }

    .caption {
        font-size: 2.5em;
    }

    .text {
        font-size: 0.9em;
    }

    .snakeBorder {
        width: 80vw;
    }

    .columns_mern {
        flex-direction: column;
        align-items: center;
    }

    .column_mern {
        width: 90%;
        margin-bottom: 2rem;
    }

    .right_fullstack {
        margin: auto;
    }

    .topContainer {
        margin: auto;
    }
}

@media (max-width: 768px) {
    .fullStack {
        padding: 1rem;
    }

    .caption {
        font-size: 2em;
    }

    .text {
        font-size: 0.8em;
    }

    .topContainer {
        flex-direction: column;
        align-items: center;
    }

    .payAfterPlacement,
    .access {
        font-size: 18px;
        margin: 0;
        padding: 5px;
    }

    .buttonAndArrow {
        padding: 1rem;
    }

    .technology {
        width: 100%;
        text-align: center;
    }

    .upArrow {
        width: 50px;
        font-size: 40px;
    }

    .right_fullstack {
        margin: auto;
    }

    .topContainer {
        margin: auto;
    }
}

@media (max-width: 480px) {
    .caption {
        font-size: 1.5em;
    }

    .text {
        font-size: 0.7em;
    }

    .input_startForFree {
        margin-bottom: 10px;
        border: solid 1px red;
    }



    .buttonContainer_StartForFree {
        margin-top: 2rem;
    }

    .buttonAndArrow {
        padding: 0.5rem;
    }

    .right_fullstack {
        margin: auto;
    }

    .topContainer {
        margin: auto;
    }

    .technology {
        font-size: 12px;
    }
}

.startForFree {
    text-align: center;
    border: solid 1px white;
    padding: 0.5rem;
    border-radius: 1em;
    box-shadow: #202020 10px 10px;
}

.paymentButton {
    position: relative;
    display: inline-block;
    padding: 19px 24px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    border: 2px solid transparent;
    border-radius: 5px;
    background: linear-gradient(to right, #007bff, #65b300);
    color: white;
    cursor: pointer;
    transition: color 0.3s ease, background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    width: 80%;
}

.paymentButton::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: red;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.4s ease-in-out;
    border-radius: 50%;
    z-index: 0;
}

.paymentButton:hover {
    color: #fff;
    border: 2px solid red;
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.7);
    transform: scale(1.05);
}

.paymentButton:hover::before {
    transform: translate(-50%, -50%) scale(1);
}

.paymentButton span {
    position: relative;
    z-index: 1;
}

.note{
    color: rgb(228, 11, 65);
    font-weight: 100;
    text-align: center ;
    padding: 0 5%;
    border: solid 1px white;
    background-color: black;
   
    
}