/* .carouselText {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
} */


.customCarousel {
  height: 88vh;
  /* Set the height to 88vh accounting for the navbar */
}

.customCarouselInner {
  height: 100%;
  /* Ensure the inner content takes full height */
}

.customCarouselItem {
  height: 100%;
  /* Each carousel item takes the full height */
}

.customCarouselItem img {
  height: 100%;
  /* Ensure the image takes full height of the carousel */
  width: 100%;
  /* Ensure the image width is 100% of the container */
  object-fit: cover;
  /* Maintain the aspect ratio and fill the container */
}

.carousel-inner {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  width: 100%;
  flex: 0 0 100%;
  /* Ensures each slide takes up 100% of the width */
  transition: transform 0.5s ease-in-out;
  /* Smooth transitions */
}


.carouselText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional background for better readability */
  padding: 20px;
  border-radius: 10px;
  /* width: 100%; */
  /* pointer-events: none; */
  /* Prevent the text from interfering with carousel controls */
  width: 50vw;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}



.leftTitleSection {
  width: 60vw;
  padding: 0 2em;
  border: outset;
  border-radius: 2em;
}

.caption {
  font-size: 3.5em;
}

.titleSection {
  background: linear-gradient(to bottom, #b8b4b4, #080808);
  padding: 2em;
  display: flex;
  color: white;
  gap: 1em;
}

.rightTitleSection img {
  width: 35vw;
  /* height: 80vh; */
  height: auto;
  border-radius: 1em;
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin: 2em 0;
}

.button {
  transition: transform 0.5s ease-in-out;
  /* Smooth transition */
  width: 30%;
  height: 8vh;
  background-color: #3be1e4;
  color: black;
  font-weight: 800;

}

.atag {
  padding-top: 10px;
}

.button:hover {
  transform: scale(1.2);
  /* Increases size by 10% when hovered */
  background-color: #EC1313;
}

.courses {
  display: flex;
  justify-content: space-around;
  gap: 5em;
  color: white;
  font-weight: 800;
  padding: 0.5em;
}

.textColorBlue {
  color: #3be1e4;
}


.courses span {
  display: inline-block;
  padding: 0.5em;
  font-weight: bold;
  color: #fff;
  background-color: #ec1313;
  /* Highlight with a blue background */
  border-radius: 5px;
  animation: blink 1.5s infinite alternate;
  /* Blinking effect */
}

.button_startForFree {
  background-image: radial-gradient(rgb(134, 49, 49), rgb(29, 209, 185));
  cursor: pointer;
}

.button_startForFree:hover {
  cursor: pointer;
}

.startForFree {
  text-align: center;
  border: solid 1px white;
  padding: 0.5rem;
  border-radius: 1em;
  box-shadow: #202020 10px 10px;
}

@keyframes blink {
  0% {
    opacity: 1;
    transform: scale(1);
    /* Normal size */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Soft glow effect */
  }

  50% {
    opacity: 0.5;
    transform: scale(1.05);
    /* Slightly enlarged */
    box-shadow: 0 0 20px rgba(0, 123, 255, 0.8);
    /* Stronger glow */
  }

  100% {
    opacity: 1;
    transform: scale(1);
    /* Back to normal size */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Soft glow */
  }
}

.snakeBorder {
  position: relative;
  display: inline-block;
  padding: 5px;
  background-image: linear-gradient(to bottom, rgb(202, 193, 193), rgb(39, 27, 27));
  /* background: transparent; */
  padding: 1rem;
  text-align: center;
  height: 100%;
  border-radius: 20px;
}

.top_startForFree {
  margin: 1em 0;
  /* color: #3be1e4; */
}

.bottom_startForFree {
  margin: 3em 0;

}

.buttonContainer_StartForFree {
  margin-top: 4rem
}

.snakeBorder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  box-sizing: border-box;
  border-image: linear-gradient(90deg, transparent, #33ff00, transparent) 1;
  border-radius: 8px;
  /* Optional: Add rounded corners */
  animation: snake 2s linear infinite;
}

@keyframes snake {
  0% {
    border-image-source: linear-gradient(90deg, transparent, #3be1e4, transparent, transparent);
  }

  25% {
    border-image-source: linear-gradient(180deg, transparent, #3be1e4, transparent, transparent);
  }

  50% {
    border-image-source: linear-gradient(270deg, transparent, #3be1e4, transparent, transparent);
  }

  75% {
    border-image-source: linear-gradient(360deg, transparent, #3be1e4, transparent, transparent);
  }

  100% {
    border-image-source: linear-gradient(450deg, transparent, #3be1e4, transparent, transparent);
  }
}


/* Responsive adjustments */
@media (max-width: 1200px) {
  .caption {
    font-size: 2.5em;
    /* Adjust caption size for medium screens */
  }

  .titleSection {
    flex-direction: column;
    /* Stack title sections vertically */
  }

  .leftTitleSection {
    width: 100%;
    /* Full width for left section */
    padding: 0 1em;
    /* Adjust padding */
  }

  .rightTitleSection {
    width: 100%;
    /* Full width for right section */

  }

  .courses {
    flex-direction: column;
    /* Stack courses vertically */
    gap: 1em;
    /* Adjust spacing */
  }

  .button {
    width: 100%;
    /* Full width buttons for better touch area */
    height: auto;
    /* Adjust height */
  }
}

@media (max-width: 768px) {
  .caption {
    font-size: 2em;
    /* Further reduce caption size */
  }

  .carouselText {
    width: 80vw;
    /* Adjust width of carousel text */
    height: auto;
    /* Allow height to adjust based on content */
    padding: 15px;
    /* Adjust padding */
  }

  .leftTitleSection {
    padding: 1em;
    /* Reduce padding */
  }

  .rightTitleSection img {
    width: 80vw;
    /* Adjust width for images */
    height: auto;
    /* Maintain aspect ratio */
  }

  .courses {
    flex-direction: column;
    /* Stack courses vertically */
    text-align: center;
    /* Center text */
  }

  .buttons {
    flex-direction: column;
    /* Stack buttons vertically */
    gap: 1em;
    /* Adjust gap */
  }

  .input_startForFree {
    margin-bottom: 15px;
    /* Adjust margin */
  }

  .buttonContainer_StartForFree {
    margin-top: 2rem;
    /* Adjust margin */
  }
}

@media (max-width: 480px) {
  .caption {
    font-size: 1.5em;
    /* Reduce caption size for small screens */
  }

  .carouselText {
    font-size: 0.9em;
    /* Adjust font size for smaller devices */
  }

  .titleSection {
    padding: 1em;
    /* Reduce padding */
  }

  .courses {
    gap: 0.5em;
    /* Reduce gap */
  }

  .button {
    height: 50px;
    /* Set a specific height for small screens */
  }
}


.companiesContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* Space between the rows */
  overflow: hidden;
  /* Hide the overflow to allow smooth scrolling */
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0.2rem;
}

/* Common row styling */
.companiesRow {
  display: flex;
  gap: 1rem;
  /* Space between the company logos */
  align-items: center;
  /* overflow: hidden; */
  /* Ensure only part of the row is visible */
  white-space: nowrap;
  /* Prevent wrapping of logos */
  padding: 0 1rem;
}

/* Left to Right Scroll */
.scrollLeftToRight {
  animation: scrollLeftToRight 20s linear infinite;
}

/* Right to Left Scroll */
.scrollRightToLeft {
  animation: scrollRightToLeft 20s linear infinite;
}

/* Company card styling */
.companyCard {
  display: inline-block;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: solid 0.5em rgb(1, 34, 27);
  cursor: pointer;

}

.companyCard:hover {
  transform: scale(1.1);
  /* Slightly enlarge on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  /* More shadow on hover */
}

/* Company logo styling */
.companyLogo {
  width: 150px;
  height: 80px;
  object-fit: contain;
  /* Maintain aspect ratio */
}

/* Scroll animations */
@keyframes scrollLeftToRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes scrollRightToLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Container for companies scrolling */
.companiesContainer {
  position: relative;
  /* height: 300px;  */
  /* overflow-y: scroll; */
  padding: 10px;
}

/* Company rows */
/* .companiesRow {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  overflow-x: auto;
} */

/* Company logo styling */
/* .companyCard {
  width: 150px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  margin: 10px;
  transition: transform 0.3s ease;
}

.companyCard:hover {
  transform: scale(1.05);
} */

/* Scroll direction */
/* .scrollLeftToRight {
  animation: scroll-left-right 20s linear infinite;
}

.scrollRightToLeft {
  animation: scroll-right-left 20s linear infinite;
}

@keyframes scroll-left-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes scroll-right-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.bigText {
  position: absolute;
  /* Position absolutely to the container */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust position to truly center */
  font-size: 2.5rem;
  /* Adjust size as needed */
  color: rgba(255, 255, 255, 0.8);
  /* Color with transparency for better visibility */
  text-align: center;
  /* Center the text */
  z-index: 0.5;
  /* Place it behind the scrolling content */
  color: #EC1313;
  background-image: radial-gradient(rgb(0, 255, 85), rgb(86, 86, 88));
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Responsive adjustments */
@media (max-width: 1200px) {
  .companiesRow {
    gap: 0.5rem;
  }

  .companyLogo {
    width: 120px;
    height: 65px;
  }
}

@media (max-width: 768px) {
  .companyLogo {
    width: 100px;
    height: 55px;
  }

  .companiesRow {
    gap: 0.3rem;
  }

  .companyCard {
    padding: 0.3rem;
  }
}

@media (max-width: 480px) {
  .companyLogo {
    width: 80px;
    height: 40px;
  }

  .companiesRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .companyCard {
    width: 40%;
  }
}

/* WhatsAppButton.css */
.whatsapp_button {
  background-color: #25D366;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.whatsapp_button:hover {
  background-color: #128C7E;
  /* Darker green on hover */
}

.whatsapp_icon {
  margin-right: 8px;
}

.whatsappButtons {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.sliderButton {
  width: 20vw;
}


/* Responsive rules */
@media (max-width: 768px) {
  .whatsappButtons {
    flex-direction: column;
    /* Stack buttons vertically */
    gap: 15px;
    /* Adjust spacing between buttons */
  }

  .sliderButton,
  .whatsapp_button {
    width: 100%;
    /* Full-width buttons */
    max-width: 300px;
    /* Restrict the max width */
    padding: 10px;
    /* Slightly reduce padding */
    font-size: 14px;
    /* Reduce font size */
  }

  .whatsapp_icon {
    font-size: 18px;
    /* Reduce icon size */
  }
}

@media (max-width: 480px) {

  .sliderButton,
  .whatsapp_button {
    width: 100%;
    /* Full-width buttons */
    padding: 8px;
    /* Further reduce padding */
    font-size: 12px;
    /* Further reduce font size */
  }

  .whatsapp_icon {
    font-size: 16px;
    /* Reduce icon size for small screens */
  }
}